@media all and (min-width:1008px) {
	.RegistrationChrome {
		background-color: #F9F9F9;
		margin: 2em 4em 4em 4em;
		padding: 5px;
		box-shadow: 1px 1px 8px #bbb;
	}
}

@media all and (min-width:568px) and (max-width: 1007px) {
	.RegistrationChrome {
		background-color: #F9F9F9;
		margin: 2em 4em 4em 4em;
		padding: 5px;
		box-shadow: 1px 1px 8px #bbb;
	}
}

@media all and (max-width: 567px) {
	.RegistrationChrome {
		padding: 10px;
	}
}
