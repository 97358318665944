



/* This is for global styles */
@media all {
	
}

/* This is for device changing styles */
@media (min-width: 319px) and (max-width: 575px) {
	aside {
		display: none;
	}

	#registration-aside-menu {
		display: none;
	}

	.list {
		display: none;
	}
}

@media (min-width: 576px) and (max-width: 767px) {
	aside {
		width: 39%;
		margin-left: 1em;
		padding-left: .5em;
		border-left: .5px solid #bbb;
		height: 100%;
	}

	#registration-aside-menu {
		list-style-type: none;
		list-style-position: outside;
		display: list-item;
		font-weight: normal;
		font-size: 10px;
	}

	.list {
		margin-bottom: 10px;
		color: gray;
	}

	.listStep {
		margin-bottom: 10px;
		border: 1px solid #3b5998;
		border-radius: 5px;
		background-color: #3b5998;
		opacity: .6;
		color: white;
		font-weight: bold;
		padding-left: 10px;
		font-size: 8px!important;
	}

	.progress-hdr1 {
		display: none;
	}

	.progress-hdr2 {
		display: none;
	}

	.progress-hdr3 {
		font-weight: bold;
	}

	.help-text {
		font-size: .55em;
		margin: .3em;
		color: gray;
	}
}

@media (min-width: 768px) and (max-width: 991px) {
	aside {
		width: 36%;
		margin-left: 1em;
		padding-left: .6em;
		border-left: 1.5px solid #bbb;
		height: 100%;
	}

	#registration-aside-menu {
		list-style-type: none;
		list-style-position: outside;
		display: list-item;
		font-weight: normal;
		font-size: 14px;
	}

	.list {
		margin-bottom: 10px;
		color: gray;
	}

	.listStep {
		margin-bottom: 10px;
		border: 1px solid #3b5998;
		border-radius: 5px;
		background-color: #3b5998;
		opacity: .6;
		color: white;
		font-weight: bold;
		padding-left: 10px;
		font-size: 11px!important;
	}

	.progress-hdr1 {
		display: none;
	}

	.progress-hdr2 {
	}

	.progress-hdr3 {
		display: none;
	}

	.help-text {
		font-size: .75em;
		margin: .3em;
		color: gray;
	}
}

@media (min-width: 992px) and (max-width: 1199px) {
	aside {
		width: 30%;
		margin-left: 1.5em;
		padding-left: 1.5em;
		border-left: 1.5px solid #bbb;
		height: 100%;
	}

	#registration-aside-menu {
		list-style-type: none;
		list-style-position: outside;
		display: list-item;
		font-weight: normal;
		font-size: 14px;
	}

	.list {
		margin-bottom: 10px;
		color: gray;
	}

	.listStep {
		margin-bottom: 10px;
		border: 1px solid #3b5998;
		border-radius: 5px;
		background-color: #3b5998;
		opacity: .6;
		color: white;
		font-weight: bold;
		padding-left: 10px;
		font-size: 12px!important;
	}

	.progress-hdr1 {
		display: none;
	}

	.progress-hdr2 {
	}

	.progress-hdr3 {
		display: none;
	}

	.help-text {
		font-size: .75em;
		margin: .3em;
		color: gray;
	}
}

@media (min-width: 1200px) {
	aside {
		width: 30%;
		margin-left: 2em;
		padding-left: 2em;
		border-left: 1.75px solid #bbb;
		height: 100%;
	}

	#registration-aside-menu {
		list-style-type: none;
		list-style-position: outside;
		display: list-item;
		font-weight: normal;
		font-size: 16px;
	}

	.list {
		margin-bottom: 10px;
		color: gray;
	}

	.listStep {
		margin-bottom: 10px;
		border: 1px solid #3b5998;
		border-radius: 5px;
		background-color: #3b5998;
		opacity: .6;
		color: white;
		font-weight: bold;
		padding-left: 10px;

	}

	.progress-hdr1 {
		font-weight: bold;
	}

	.progress-hdr2 {
		display: none;
	}

	.progress-hdr3 {
		display: none;
	}

	.help-text {
		font-size: .95em;
		margin: .3em;
		color: gray;
	}
}

@media (min-width: 1558px) {
	
}
