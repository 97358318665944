.geosuggest {
	font-size: 1rem;
	position: relative;
	width: 100%;
	margin: 0px;
	text-align: left;
}

.geosuggest__input {
	width: 100%;
	border: none;
	box-shadow: 0 0 1px #3d464d;
	-webkit-transition: border 0.2s, box-shadow 0.2s;
			transition: border 0.2s, box-shadow 0.2s;

	height: 33px;
	padding: 10px;
	font-size: 14px;
	/* TODO: Possioble throw away; */
	line-height: 1.333333333;
	border-radius: 4px;
	/* TODO: Possioble throw awaysssss; */
	display: block;
	color: #555;
	background-color: #fff;
	background-image: none;
	border: 1px solid #8a6d3b;
	-webkit-box-shadow: inset 0 1px rgba(0,0,0,.075);
	box-shadow: inset 0 1px rgba(0,0,0,.075);
	-webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
	-o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
	transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

.geosuggest__input:focus {
	color: #495057;
	background-color: #fff;
	border-color: #80bdff;
	outline: 0;
	box-shadow: 0 0 0 .2rem rgba(0,123,255,.25);
}

.geosuggest__suggests {
	position: absolute;
	top: 100%;
	left: 0;
	right: 0;
	max-height: 25em;
	padding: 5px;
	margin-top: 5px;
	background: #f8f9fa;
	border: 1px solid green;
	border-top-width: 1px;
	overflow-x: hidden;
	overflow-y: auto;
	list-style: none;
	z-index: 5;
	-webkit-transition: max-height 0.2s, border 0.2s;
			transition: max-height 0.2s, border 0.2s;
}

.geosuggest__suggests--hidden {
	max-height: 0;
	overflow: hidden;
	border-width: 0;
}

.geosuggest__item {
	font-size: 14px;
	cursor: pointer;
}

.geosuggest__item:hover,
.geosuggest__item:focus {
  background: #f5f5f5;
}

.geosuggest__item--active {
  background: #267dc0;
  color: #fff;
}

.geosuggest__item--active:hover,
.geosuggest__item--active:focus {
  background: #ccc;
}

.geosuggest__item__matched-text {
  font-weight: bold;
}

.help {
	font-size: .75em;
	margin: .3em;
	color: gray;
}

.default-num-style {
	display: none;
}

.default-help-style {
	display: none;
}

/* This is for global styles */
@media all {
	
}

/* This is for device changing styles */
@media (min-width: 319px) {
	div .hdr1 {
		display: none;
	}

	div .hdr2 {
		display: none;
	}

	div .hdr4 {
		box-sizing: border-box;
		display: flex; 
		flex-flow: column;
		justify-content: center;
		width: 100%;
		margin: 0px;

	}

	.progress {
		display: -ms-flexbox;
		display: flex;
		height: 1.2rem;
		overflow: hidden;
		font-size: .65rem;
		background-color: #e9ecef;
	}

	#progressBar {
		margin-right: 0px;
		margin-bottom: 10px;
		margin-top: 5px;
	}

	.signup {
		box-sizing: border-box;
		display: flex;
		flex-flow: row wrap;
	}

	.form-sm {
		width: 100%;
		margin-bottom: 30px;
	}

}

@media (min-width: 576px) {
	div .hdr1 {
		display: none;
	}


	div .hdr2 {
		display: none;
	}

	div .hdr4 {
		box-sizing: border-box;
		display: flex; 
		flex-flow: column;
		justify-content: center;
		width: 100%;
		margin: 0px;
	}

	.progress {
		display: -ms-flexbox;
		display: flex;
		height: 1.5rem;
		overflow: hidden;
		font-size: .75rem;
		background-color: #e9ecef;
	}

	#progressBar {
		margin-right: 0px;
		margin-bottom: 10px;
		margin-top: 5px;
	}

	#logo {
		display: none;
	}

	.form {
		width: 55%;
		margin-bottom: 30px;
	}
	
}

@media (min-width: 768px) {
	div .hdr1 {
		display: none;
	}

	div .hdr2 {
		box-sizing: border-box;
		display: flex; 
		flex-flow: column;
		justify-content: center;
		width: 100%;
		margin: 0px;
	}

	div .hdr4 {
		display: none;
	}

	.progress {
		display: -ms-flexbox;
		display: flex;
		height: 1.75rem;
		overflow: hidden;
		font-size: .85rem;
		background-color: #e9ecef;
	}
	
}

@media (min-width: 992px) {
	div .hdr1 {
		box-sizing: border-box;
		display: flex; 
		flex-flow: column;
		justify-content: center;
		width: 100%;
		margin: 0px;
	}

	div .hdr2 {
		display: none;
	}

	div .hdr4 {
		display: none;
	}

}

@media (min-width: 1200px) {

	.progress {
		display: -ms-flexbox;
		display: flex;
		height: 1.75rem;
		overflow: hidden;
		font-size: 1.25rem;
		background-color: #e9ecef;
	}
	
}

@media (min-width: 1558px) {
	
}














