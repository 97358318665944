.App {
	margin: 0px;
	padding: 0px;
}

.app-navi-bar {
	width: 100%!important;
}

/*
.containter{
	max-width: 100%;
}

@media all and (max-width: 374px){
	.App .logo {
		width: 5em;
	}

	.dropdown-menu {
		position: absolute;
		top: 100%;
		left: 0;
		z-index: 1000;
		display: none;
		float: left;
		min-width: 13rem;
		padding-left: 15px;
		margin: .125rem;
		font-size: 1rem;
		color: #212529;
		text-align: left;
		list-style: none;
		background-color: #fff;
		background-clip: padding-box;
		border: 1px solid rgba(0,0,0,.15);
		border-radius: .25rem;
	}

	.navi-link {
		color: rgba(0,0,0,.5) !important;
	}

	.navi-link:hover {
		width: 100%;
		color: #16181b !important;
		text-decoration: none;
		background-color: #f8f9fa;
	}

	.navi-link:active {
		color: rgba(0,0,0,.9) !important;
		text-decoration: none;
		background-color: #f8f9fa;
	}

	.navbar-nav .nav-link.active {
		color: rgba(0,0,0,.9) !important;
	}
}
*/

/* This is for global styles */
@media all {

	.navi-link {
		color: rgba(0,0,0,.5) !important;
	}

	.navi-link:hover {
		color: #16181b !important;
		text-decoration: none;
		background-color: #f8f9fa;
	}

	.navi-link:active {
		color: rgba(0,0,0,.9) !important;
		text-decoration: none;
		background-color: #f8f9fa;
	}

	.navbar-nav .nav-link.active {
		color: rgba(0,0,0,.9) !important;
	}
	
}

/* This is for device changing styles */
@media (min-width: 319px) {

	.navbar-expand-lg .navbar-nav .nav-link {
		padding-right: .5rem;
		padding-left: .5rem;
	}

	.container {
		max-width: 100%;
	}

	.App .logo {
		width: 5em;
	}

	.dropdown-menu {
		color: #212529;
		background-color: #fff;
		background-clip: padding-box;
		border: 1px solid rgba(0,0,0,.15);
		border-radius: .25rem;
	}
}

@media (min-width: 319px) and (max-width: 575px) {

	#search-form {
		display: -ms-flexbox;
		display: flex;
		-ms-flex-flow: row;
		flex-flow: row;
		font-size: 12px;
		width: 100%
	}

	#search-btn {
		margin-left: .25em;
	}

}

@media (min-width: 576px) {
	.container {
		max-width: 100%;
	}

	.App .logo {
		width: 8em;
	}
}

@media (min-width: 768px) {
	.containter{
		max-width: 100%;
	}

	.App .logo {
		width: 9em;
	}
}

@media (min-width: 992px) {

	.App nav div {
		font-size: 13px;
	}
	
	.App .containter{
		max-width: 100%;
	}

	.App .logo {
		width: 6em;
	}
}

@media (min-width: 1200px) {
	.containter{
		max-width: 100%;
	}

	.App .logo {
		width: 9em;
	}
}

@media (min-width: 1558px) {
	.containter{
		max-width: 100%;
	}

	.App .logo {
		width: 10em;
	}

	#search-form {

	}

	#search-btn {
		margin-left: .25em;
	}
}




