.container {
	padding-left: 0px;
	padding-right: 0px;
}

.New_KnowledgeBase_Article {
	background-color: #F9F9F9;
	margin: 0px;
	padding: 0px;
	box-shadow: 1px 1px 8px #bbb;
}

.kb-chrome {
	margin: 10px!important;
}

.kb-title-sm {
	padding: 5px 0px 0px 8px;
}

.kb-title {
	padding: 5px 0px 0px 0px;
}

.article {
	margin: 0px;
}

.article-num-row-sm {
	padding: 5px 0px 0px 8px!important;
	margin: 0px;
}

.article-num-label-sm {
	padding: 0px;
	margin: 0px;
	width: 30%;
}

.article-num-field {
	padding: 0px;
	margin: 0px;
	width: 70%;
}

.subject-group {
	padding: 8px!important;
}

.content-group {
	padding: 8px!important;
}

.attachment-group {
	padding: 8px!important;
}

.loader-btn-group {
	padding: 8px!important;
}

.case-fields {
	width: 100%;
	font-size: 18px;
}
