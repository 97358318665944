.parallax-menu {
	min-height: 100vh;
}

.home-carousel {
	position: relative;
}

.btn-container {
	display: flex!important;
	justify-content: center;
}

.modal-btn-sm-portrait {
	position: absolute;
	margin-top: 140px;
	font-variant-caps: all-small-caps;
	opacity: 0.9;

	/*left: 20%;*/
	width: 200px;
	color: white;

	border-style: solid;
	border-width: 0.05em;
	border-radius: .5em;
	border-color: #580000;
	
	margin-bottom: 50px;
	box-shadow: .5px .5px 2px .5px #580000;
	transition: .2s;
	-webkit-transition: .2s;
	cursor: pointer;
	
}

.modal-btn-sm-portrait:hover, .modal-btn-sm-portrait:focus, .modal-btn-sm-portrait:active {
	color: #bbb;
	opacity: 0.7;
	border-width: 0.05em;
	box-shadow: 0px 0px 0px 0px black;
	transition: .2s;
	-webkit-transition: .2s;
	cursor: pointer;
}

.carousel-title-1 {


}

.carousel-p-1 {

	color: white!important;

}

.carousel-title-3 {


}

.carousel-p-3 {

	color: black!important;

}

.dmv-img {
  object-fit: cover;

  width: 100%;
  height: 550px;
}

.solution-header {
	margin-top: 20px;
}

.solution-container {
	box-sizing: border-box;
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
	font-weight: bold;
	padding-bottom: 20px;
}

.service-container {
	margin-top: 2em;
	margin-bottom: 2.0em;
	margin-left: 1.0em;
	margin-right: 1.0em;
}

.service-block {
	text-decoration: none!important;
	color: black;
	text-align: center;
}

.service-block:hover, .service-block:focus, .service-block:active {
	text-decoration: none!important;
	color: black;
}

.service-button {
	box-sizing: border-box;
	width: 17.5em;
	border-style: solid;
	border-width: 0.0125em;
	border-radius: 1em;
	border-color: #bbb;
	margin-left: 20px;
	margin-right: 20px;
	box-shadow: 1px 1px 4px 1px #bbb;
	transition: .2s;
	-webkit-transition: .2s;
	-moz-transition: .2s;
	-o-transition: .2s;
	cursor: pointer;
	transform-style: preserve-3d;
	-webkit-transform-style: preserve-3d;
	-moz-transform-style: preserve-3d;
	-ms-transform-style: preserve-3d;
}

.service-button:hover, .service-button:focus, .service-button:active {
	background-color: #f4f8ff;
	box-shadow: 0px 0px 0px 0px #bbb;
	transition: .2s;
	-webkit-transition: .2s;
	-moz-transition: .2s;
	-o-transition: .2s;
	cursor: pointer;
	transform-style: preserve-3d;
	-webkit-transform-style: preserve-3d;
	-moz-transform-style: preserve-3d;
	-ms-transform-style: preserve-3d;
	opacity: .79;
}

.service-icon {
	width: 80%;
	margin-top: 10px;
	margin-bottom: 20px;
}

.service-title {
	padding-top: 10px;
	font-size: 1.5em;
	font-variant: small-caps;
}

.service-tagline {
	margin-top: 2em;
	margin-left: 2em;
	margin-bottom: 2em;
	width: 16em;
	font-weight: bold;
	color: #3B5998!important;
	justify-content: center;
}

.lander-button {
	font-weight: bold;
	padding-left: 10px;
	padding-right: 10px;
	padding-top: 12px;
	padding-bottom: 12px;
	border-radius: 0.95em;
	box-shadow: 2px 2px 6px 2px #bbb;
	text-decoration: none!important;
	color: white;
	background-color: #3B5998;

	transition: .2s;
	-webkit-transition: .2s;
	-moz-transition: .2s;
	-o-transition: .2s;
	cursor: pointer;
	transform-style: preserve-3d;
	-webkit-transform-style: preserve-3d;
	-moz-transform-style: preserve-3d;
	-ms-transform-style: preserve-3d;	
}

.lander-button:hover, .lander-button:focus, .lander-button:active {
	
	color: #e1e1e1;
	background-color: #3B5998;
	box-shadow: 0px 0px 0px 0px #bbb;
	transition: .2s;
	-webkit-transition: .2s;
	-moz-transition: .2s;
	-o-transition: .2s;
	cursor: pointer;
	transform-style: preserve-3d;
	-webkit-transform-style: preserve-3d;
	-moz-transform-style: preserve-3d;
	-ms-transform-style: preserve-3d;	
}











