

.jumbo {
    padding: 2rem 1rem!important;
    margin: 2rem!important;
    background-color: #e9ecef;
    border-radius: .3rem!important;
}

.f-container {
	display: flex!important;
}

.f-p {
	margin: 20px;
}

.f-heading {
	padding-top: 0px;
}

section {
	color: #999!important;
	text-align: center;
}