.p-modal {
	font-size: 12px !important;
	margin-bottom: 0.4rem;
}

.modal-body {
	position: relative;
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	padding: 0.5rem;
}

.main-modal {
}

.zoom-fields {
	font-size: 18px !important;
}

.mcase-fields {
	width: 100%;
	font-size: 18px !important;
}

.renew-btn {
	cursor: pointer;
}

.billing-row-1 {
	margin-right: 0px !important;
	margin-left: 0px !important;
}

.billing-row-2 {
	margin-right: 0px !important;
	margin-left: 0px !important;
}

.billing-group {
	margin-bottom: 0.4rem;
}

.billing-address-2 {
	max-width: 35%;
	padding-right: 0px;
	padding-left: 0px;
}

.billing-city {
	max-width: 65%;
	padding-right: 0px;
}

.billing-state {
	max-width: 35%;
	padding-right: 0px;
	padding-left: 0px;
}

.billing-zip {
	max-width: 65%;
	padding-right: 0px;
}

.geosuggest {
	font-size: 1rem;
	position: relative;
	width: 100%;
	margin: 0px;
	text-align: left;
}

.geosuggest__input {
	width: 100%;
	border: none;
	box-shadow: 0 0 1px #3d464d;
	-webkit-transition: border 0.2s, box-shadow 0.2s;
	transition: border 0.2s, box-shadow 0.2s;

	height: 33px;
	padding: 10px;
	font-size: 14px;
	/* TODO: Possioble throw away; */
	line-height: 1.333333333;
	border-radius: 4px;
	/* TODO: Possioble throw awaysssss; */
	display: block;
	color: #555;
	background-color: #fff;
	background-image: none;
	border: 1px solid #8a6d3b;
	-webkit-box-shadow: inset 0 1px rgba(0, 0, 0, 0.075);
	box-shadow: inset 0 1px rgba(0, 0, 0, 0.075);
	-webkit-transition: border-color ease-in-out 0.15s,
		box-shadow ease-in-out 0.15s;
	-o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
	transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.geosuggest__input:focus {
	color: #495057;
	background-color: #fff;
	border-color: #80bdff;
	outline: 0;
	box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.geosuggest__suggests {
	position: absolute;
	top: 100%;
	left: 0;
	right: 0;
	max-height: 25em;
	padding: 0px !important;
	margin-top: 0px !important;
	background: #f8f9fa;
	border: 1px solid green;
	border-top-width: 1px;
	overflow-x: hidden;
	overflow-y: auto;
	list-style: none;
	z-index: 5;
	-webkit-transition: max-height 0.2s, border 0.2s;
	transition: max-height 0.2s, border 0.2s;
}

.geosuggest__suggests--hidden {
	max-height: 0;
	overflow: hidden;
	border-width: 0;
}

.geosuggest__item {
	font-size: 14px;
	cursor: pointer;
}

.geosuggest__item:hover,
.geosuggest__item:focus {
	background: #f5f5f5;
}

.geosuggest__item--active {
	background: #267dc0;
	color: #fff;
}

.geosuggest__item--active:hover,
.geosuggest__item--active:focus {
	background: #ccc;
}

.geosuggest__item__matched-text {
	font-weight: bold;
}

.help {
	font-size: 0.75em;
	margin: 0.3em;
	color: gray;
	margin-top: 20px !important;
}

.default-num-style {
	display: none;
}

.default-help-style {
	display: none;
}

.email-field {
	margin-right: 0px;
	margin-left: 0px;
}

.email-col-1 {
	padding-left: 0px;
}

.email-col-2 {
	padding-right: 0px;
}

.billing-name-field {
	margin-right: 0px;
	margin-left: 0px;
}

.billing-name-col-1 {
	padding-left: 0px;
}

.billing-name-col-2 {
	padding-right: 0px;
}

.vin-row-field {
	margin-right: 0px;
	margin-left: 0px;
}

.plate-row-field {
	margin-right: 0px;
	margin-left: 0px;
}

.zip-row-field {
	margin-right: 0px;
	margin-left: 0px;
}

.vin-col-1 {
	padding-left: 0px;
}

.vin-col-2 {
	padding-right: 0px;
}

.plate-col-1 {
	padding-left: 0px;
}

.plate-col-2 {
	padding-right: 0px;
}

.zip-col-1 {
	padding-left: 0px;
}

.zip-col-2 {
	padding-right: 0px;
}
