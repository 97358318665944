.dash-container {
    box-sizing: border-box;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    font-weight: bold;
}

.dasher-container {
    box-sizing: border-box;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    font-weight: bold;
}

.toolbar-container {
    box-sizing: border-box;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
}

.toolbar-container-sm {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}

.toolbar-container-sm .btn-toolbar {
	width: 100%;
}

.btn-toolbar {
    box-sizing: border-box;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    width: 50%;
    margin: 0px!important;
}

.btn-primary {
	color: black;
	border: .75px dotted #007DC6;
	background-color: white;
	box-shadow: -1px 1px 3px 0px #bbb;

    transition: .2s!important;
    -webkit-transition: .2s!important;
    -moz-transition: .2s!important;
    -o-transition: .2s!important;
    cursor: pointer;
    transform-style: preserve-3d!important;
    -webkit-transform-style: preserve-3d!important;
    -moz-transform-style: preserve-3d!important;
    -ms-transform-style: preserve-3d!important;
}

.btn-primary:hover {
	color: black;
	border-color: #007DC6;
	background-color: white;
	box-shadow: -2px 2px 8px 0px #bbb;

    transition: .2s!important;
    -webkit-transition: .2s!important;
    -moz-transition: .2s!important;
    -o-transition: .2s!important;
    cursor: pointer;
    transform-style: preserve-3d!important;
    -webkit-transform-style: preserve-3d!important;
    -moz-transform-style: preserve-3d!important;
    -ms-transform-style: preserve-3d!important;
}

.btn-primary:active {
	color: black!important;
	border-color: #007DC6;
	background-color: white!important;
	box-shadow: -2px 2px 8px 0px #bbb;

    transition: .2s!important;
    -webkit-transition: .2s!important;
    -moz-transition: .2s!important;
    -o-transition: .2s!important;
    cursor: pointer;
    transform-style: preserve-3d!important;
    -webkit-transform-style: preserve-3d!important;
    -moz-transform-style: preserve-3d!important;
    -ms-transform-style: preserve-3d!important;
}

.dropdown-menu {
	position: absolute!important;

	left: 0;
	z-index: 1000;
	display: none;
	float: left;
	min-width: 10rem;
	padding: .5rem 0;
	margin: .125rem 0 0;
	font-size: 1rem;
	color: #212529;
	text-align: left;
	list-style: none;
	background-color: #fff!important;
	background-clip: padding-box!important;
	border: 1px solid rgba(0,0,0,.15)!important;
	border-radius: .25rem!important;
	border-bottom-left-radius: .25rem!important;
	border-bottom-right-radius: .25rem!important;
}

.dropdown-menu.show {
	display: block;
	max-height: 500px;
	width: 250px!important;
	overflow-y: scroll;
	margin-top: 10px;
	margin-right: 10px;
	box-shadow: 1px 1px 8px #bbb;
}

.container-fluid {
	width: 100%;
	padding-right: 0px;
	padding-left: 0px;
	margin-right: 0px;
	margin-left: 0px;
}

#card-group {
	height: 295px;
	overflow-y: hidden;
	scroll-behavior: smooth;
}

.user-title-card {
	padding-top: 10px;
	margin: 0px;
}

hr {
	margin-top: .5rem;
	margin-bottom: .5rem;
	border: 0;
	border-top: 1px solid rgba(0,0,0,.1);
}

hr {
	box-sizing: content-box;
	height: 0;
	overflow: visible;
}

.card-lists {
	max-height: 208px!important;
}

.list-item {
	margin: 0px;
}

.list-date {
	font-size: 13px;
	margin-bottom: 0px;
}

.card-container-sm {
	width: 100%;
	height: 295px;

	background-color: #F9F9F9;
	margin-top: 20px;
	margin-left: 20px!important;
	margin-right: 20px!important;
	margin-bottom: 20px!important;
	padding: 0px;
	box-shadow: 1px 1px 8px #bbb;
}

.card-container {
	width: 275px;
	height: 295px;

	background-color: #F9F9F9;
	margin-top: 0px;
	margin-left: 20px!important;
	margin-right: 20px!important;
	margin-bottom: 20px!important;
	padding: 0px;
	box-shadow: 1px 1px 8px #bbb;
}

.search-container {
	width: 100%;
	background-color: #F9F9F9;
	margin: 0px;
	padding: 0px;
	box-shadow: 1px 1px 8px #bbb;
}

.header-container {
	padding: 20px;
}

.split-button-search {
	margin: 10px;
	width: 40em;
}

.dropdown-options{
	margin: 10px;
	width: 100%;
}

#dropdown-button-drop-left {
	max-width: 10%;
	color: black;
	background-color: white;
	box-shadow: 2px 2px 8px #bbb!important;

    transition: .2s!important;
    -webkit-transition: .2s!important;
    -moz-transition: .2s!important;
    -o-transition: .2s!important;
    cursor: pointer;
    transform-style: preserve-3d!important;
    -webkit-transform-style: preserve-3d!important;
    -moz-transform-style: preserve-3d!important;
    -ms-transform-style: preserve-3d!important;
}

#dropdown-button-drop-left:hover {
	max-width: 10%;
	color: black;
	background-color: #ceedff;
	box-shadow: 2px 2px 8px #bbb;

    transition: .2s!important;
    -webkit-transition: .2s!important;
    -moz-transition: .2s!important;
    -o-transition: .2s!important;
    cursor: pointer;
    transform-style: preserve-3d!important;
    -webkit-transform-style: preserve-3d!important;
    -moz-transform-style: preserve-3d!important;
    -ms-transform-style: preserve-3d!important;
}

#dropdown-button-drop-left:active {
	max-width: 10%;
	color: black;
	background-color: #9bcce9!important;
	box-shadow: 0px 0px 0px #F9F9F9!important;

    transition: .2s!important;
    -webkit-transition: .2s!important;
    -moz-transition: .2s!important;
    -o-transition: .2s!important;
    cursor: pointer;
    transform-style: preserve-3d!important;
    -webkit-transform-style: preserve-3d!important;
    -moz-transform-style: preserve-3d!important;
    -ms-transform-style: preserve-3d!important;
}

.ui-card {
	margin: 10px;
    flex: 1 1 0; /* flex: 1, which you had before, is equivalent but doesn't work */
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
}

.search-card {
	margin: 10px;

    flex: 1 1 0; /* flex: 1, which you had before, is equivalent but doesn't work */
    display: flex;
    flex-direction: column;
}

