.container {
	padding-left: 0px;
	padding-right: 0px;
}

.article-record {
	background-color: #F9F9F9;
	margin: 0px;
	padding: 0px;
	box-shadow: 1px 1px 8px #bbb;
}

.article-chrome {
	margin: 10px!important;
}

.existing-article-title-sm {
	padding: 5px 0px 0px 0px;
}

.existing-article-title {
	padding: 5px 0px 0px 0px;
}

.article-form {
	margin: 0px;
	padding-left: 5px;
	padding-right: 5px;
}

.article-num-row {
	padding: 5px 0px 0px 0px!important;
	margin: 0px;
}

.article-num-label {
	padding: 0px;
	margin: 0px;
	width: 31%;
}

.article-num-field {
	padding: 0px;
	padding-left: 10px;
	margin: 0px;
	width: 65%;
}

.subject-group {
	padding: 8px!important;
}

.content-group {
	padding: 8px!important;
}

.attachment-group {
	padding: 8px!important;
}

.loader-btn-group {
	padding: 8px!important;
}

.case-fields {
	width: 100%;
	font-size: 18px;
}
