.record-sm {
	padding: 0px!important;
}

.record-md {
	margin: 8px!important;
}

.forms {
	padding-bottom: 35px;
}

.existing-case-title-sm {
	padding: 10px 0px 10px 8px;
	height: 5px!important;
}

.existing-case-title-md {

}

.landscape-media-sm {
	margin: 0px;
	padding: 20px;
	width: 100%;
}

.CaseRecordsList-sm {
	padding: 8px;
}

.CaseRecordsList {
	background-color: #F9F9F9;
	margin-top: 1em;
	margin-right: 0px;
	margin-left: 0px;
	padding: 0px;
	box-shadow: 1px 1px 8px #bbb;
}

.case-num-row-sm {
	width: 100%;
	margin: 0px!important;
}

.case-num-row-md {
	width: 100%;
	margin: 0px!important;
}

.case-number-sm {
	font-size: 16px;
	padding: 0px!important;
	width: 180px;
}

.case-number-md {
	font-size: 16px;
	padding: 0px!important;
	width: 180px;
}

.number-field-sm {
	padding: 0px!important;
}

.number-field-md {
	padding: 0px!important;
}

.number-field-sm2 {
	padding: 0px;
	margin-left: 10px;
}

.number-field-md2 {
	padding: 0px;
	margin-left: 0px;
}

.incident-date-row-sm {
	margin: 0px;
}

.incident-date-row-md {
	margin: 0px;
}

.incident-date-sm {
	padding: 0px;
	width: 180px;
}

.incident-date-md {
	padding: 0px;
	width: 180px;
}

.date-field-sm {
	margin: 0px;
	padding: 0px;
}

.date-field-md {
	margin: 0px;
	padding: 0px;
}

.date-field-sm2 {
	padding: 0px;
	margin-left: 10px;
}

.date-field-md2 {
	padding: 0px;
	margin-left: 0px;
}

.corp-name-row-sm {
	margin-left: 0px;
	margin-right: 0px;
	margin-bottom: 2px!important;
	width: 100%;
}

.corp-name-row-md {
	margin-left: 0px;
	margin-right: 0px;
	margin-bottom: 5px!important;
	width: 100%;
}

.corp-name-label-sm {
	padding: 0px;
	margin-right: 0px;
	width: 180px;
}

.corp-name-label-md {
	padding: 0px;
	margin-right: 0px;
	width: 20px;
}

.corp-name-field-sm {
	font-size: 18px;
	margin: 0px;
	padding: 0px;
}

.corp-name-field-md {
	font-size: 18px;
	margin: 0px;
	padding: 0px;
}

.corp-name-field-sm2 {
	padding: 0px;
	margin-left: 10px;
}

.corp-name-field-md2 {
	padding: 0px;
	margin-left: 0px;
}

.corp-contact-row {
	margin-bottom: 10px;
}

.status-row-sm {
	width: 100%;
}

.status-row-md {
	width: 100%;
}

.tix-status-sm {

}

.tix-status-md {
	width: 45%;
}

.urgency-col-sm {

}

.urgency-col-md {
	padding-right: 0px;
	width: 45%;
}

.vin-plate-row {
	width: 100%;
}

.vin-md {
	width: 45%;
}

.plate-col-md {
	width: 45%;
	padding-right: 0px;
}

.year-make-model-row {
	width: 100%;
}

.vehicle-year-md {
	width: 30%;
}

.vehicle-make-md{
	width: 30%;
	padding-right: 0px;
}

.vehicle-model-md {
	width: 33%;
	padding-right: 0px;
}

.case-subject-row-sm {
	margin-bottom: 10px;
}

.case-field-subject {
	width: 100%;
}

.case-description {
	height: 300px;
	font-size: 20px;
}

.case-field {
	width: 275px;
	font-size: 18px;
}

.text-mute {
	margin-top: 0px;
	padding-left: 15px;
	color: #6c757d!important;
}

.date {
	padding-right: 15px;
	width: 169px!important;
}