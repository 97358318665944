.Home {
	margin: 0px;
}

.Dashboard {
	margin: 0px;
}

.Home .LandingPage {
	/*padding: 80px 0;*/
	text-align: center;
}

.Home .LandingPage h1 {
  font-family: "Open Sans", "Segoe UI", "DejaVu Sans", sans-serif;
	font-weight: 600; 
}

.Home .LandingPage p{
	color: #999;
}

.Home .Dashboard h2 {
	padding-top: 0px;
  	font-family: "Open Sans", "Segoe UI", "DejaVu Sans", sans-serif;
	font-weight: 600; 
}

.Home .Dashboard h4, h5 {
  font-family: "Open Sans", "Segoe UI", "DejaVu Sans", sans-serif;
	font-weight: 600;
	overflow: hidden;
	line-height: 1.5;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.Home .Dashboard p {
	color: #666;
}

.Home .Dashboard h4, h5 {
	cursor: pointer;
}