.forms {
	margin-bottom: 15px;
}

.case-description {
	height: 300px;
	font-size: 20px;
}

.text-mute {
	margin-left: -15px;
	color: #6c757d!important;
	width: 100%!important;
}

div .CaseRecordChrome form p {
	margin-top: 0px;
	margin-bottom: 0;
}

/* This is for global styles */
@media all {
	.company-details {
		padding: 0px;
		margin: 0px;
		width: 100%;
	}
	
	.company-name{
		padding-left: 0px;
		padding-right: 0px;
		width: 50%;
	}

	.contact-name {
		padding-left: 10px;
		padding-right: 0px;
		width: 50%;
	}
}

/* This is for device changing styles */
@media (min-width: 319px) and (max-width: 567px) {
	.record {
		padding: 8px!important;
	}

	.CaseRecordChrome {

	}

	div.form-group.row {
		margin-bottom: 0px;
		width: 100%!important;
	}

	.new-case-title {
		padding-left: 8px;
		height: 5px!important;
	}

	.case-record-number-sm {
		font-size: 16px;
		padding-left: 16px;
		padding-top: .159rem!important;
		width: 100px;
		margin-bottom: 0px!important;
	}

	.case-record-number-md {
		display: none;
	}

	.case-record-number-lg {
		display: none;
	}

	.case-record-number-xl {
		display: none;
	}

	.number-field-sm {
		width: 125px;
		margin-left: 0px;
		padding-left: 4px;
		padding-top: 0px;
		padding-bottom: 0px!important;
		text-align: left!important;
	}

	.case-fields {
		width: 100%;
		font-size: 18px;
	}

	.number-field-md {
		display: none;
	}

	.number-field-lg {
		display: none;
	}

	.number-field-lg1{
		display: none;
	}

	.number-field-lg2{
		display: none;
	}

	.number-field-xl {
		display: none;
	}

	.date-label-sm {
		padding-left: 16px;
		margin-bottom: 0px!important;
		padding-right: 45px;
	}

	.date-label-md {
		display: none;
	}

	.date-label-lg {
		display: none;
	}

	.date-label-xl {
		display: none;
	}

	.date-field-sm {
		width: 198px;
		padding-top: 0px;
		margin-left: 50px;
	}

	.date-field-md {
		display: none;
	}

	.date-field-lg {
		display: none;
	}

	.date-field-xl {
		display: none;
	}

	#corpName.case-fields {
		width: 100%;
		font-size: 18px;
		margin-right: 0px!important;
	}

	.urgent-sm {
		padding-left: 0px;
	}

	.incident-sm {
		padding-left: 0px;
	}

	.urgent-md {
		display: none;
	}

	.incident-md {
		display: none
	}

	#urgent-row-md {
		display: none;
	}

	.date-label {
		margin-right: 10px;
	}

	.DateInput_input__focused {
		outline: 0;
		background: transparent;
		border: 0;
		border-top: 0;
		border-right: 0;
		border-bottom: 2px solid #008489;
		border-left: 0;
		border-radius: 2px!important;
	}

	.DateInput_input {
		font-weight: 200;
		font-size: 19px;
		line-height: 24px;
		color: #484848;
		background-color: transparent!important;
		width: 100%;
		padding: 11px 11px 9px;
		border: 0;
		border-top: 0;
		border-right: 0;
		border-bottom: 2px solid transparent;
		border-left: 0;
		border-radius: 4px;
	}

	.DateInput {
		margin: 0px;
		padding: 0px;
		background: transparent!important;
		position: relative;
		display: inline-block;
		vertical-align: middle;
	}

	.SingleDatePickerInput__withBorder {
		border-radius: 4px!important;
		border: 2px solid #dbdbdb!important;
	}

	#vin.case-fields {
		width: 100%;
		font-size: 18px;
		margin-right: 0px!important;
	}

	#plate.case-fields {
		width: 100%;
		font-size: 18px;
		margin-right: 0px!important;
	}

	#year.case-fields {
		width: 100%;
		font-size: 18px;
		margin-right: 0px!important;
	}

	#make.case-fields {
		width: 100%;
		font-size: 18px;
		margin-right: 0px!important;
	}

	#model.case-fields {
		width: 100%;
		font-size: 18px;
		margin-right: 0px!important;
	}

	#subject.case-fields {
		width: 100%;
		font-size: 18px;
		margin-right: 0px!important;
	}
}

@media (min-width: 568px) and (max-width: 767px) {
	.record {
		padding: 8px!important;
	}

	div.form-group.row {
		margin: 0px;
		width: 100%!important;
	}

	.CaseRecordChrome {
		background-color: #F9F9F9;
		margin: 1em 1.5em 2em 1.5em;
		padding: 5px;
		box-shadow: 1px 1px 8px #bbb;
	}

	.new-case-title {
		padding-left: 8px;
		padding-top: 10px;
		padding-bottom: 20px;
		height: 5px!important;
	}

	.case-record-number-sm {
		display: none;
	}

	.case-record-number-md {
		font-size: 16px;
		padding-left: 0px;
		padding-top: .159rem!important;
		width: 95px;
		margin-bottom: 0px!important;
	}

	.case-record-number-lg {
		display: none;
	}

	.case-record-number-xl {
		display: none;
	}

	.number-field-sm {
		display: none;
	}

	.number-field-md {
		width: 125px;
		margin-left: 0px;
		padding-left: 0px;
		padding-top: 0px;
		padding-bottom: 0px!important;
		text-align: left!important;
	}

	.number-field-lg {
		display: none;
	}

	.number-field-lg1{
		display: none;
	}

	.number-field-lg2{
		display: none;
	}

	.number-field-xl {
		display: none;
	}

	.date-label-sm {
		display: none;
	}

	.date-label-md {
		padding-left: 0px;
		margin-bottom: 0px!important;
		padding-right: 55px!important;
	}

	.date-label-lg {
		display: none;
	}

	.date-label-xl {
		display: none;
	}

	.date-field-sm {
		display: none;
	}

	.date-field-md {
		width: 199px;
		padding-top: 0px;
		margin-left: 55px;
	}

	.date-field-lg {
		display: none;
	}

	.date-field-xl {
		display: none;
	}

	#corpName.case-fields {
		width: 100%;
		font-size: 18px;
		margin-right: 0px!important;
	}

	.case-fields {
		width: 100%;
		font-size: 18px;
	}

	.urgent-sm {
		display: none;
	}

	.incident-sm {
		display: none;
	}

	.incident-date {
		padding-left: 10px;
	}

	.urgent-md {
		padding-left: 0px;
	}

	.incident-md {
		padding: 0px;
	}

	.container-row {
		padding: 0px;
	}

	.col-vin {
		width: 50%;
	}

	.vehicle-vin-plate {
		width: 50%;
	}

	.col-plate {
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%;
		padding-right: 0px;
		padding-left: 10px;
	}

	.vehicle-year {
		padding-left: 0px;
		width: 20%!important;
	}

	.vehicle-make {
		padding-left: 10px;
		padding-right: 0px;
		width: 40%!important;
	}

	.vehicle-model {
		padding-left: 10px;
		padding-right: 0px;
		width: 40%!important;
	}

	#date-field1 {
	}

	#date-field2 {
	}

	#urgent-row-md {
		padding-left: 0px;
	}

	.date-label {
		margin-right: 10px;
	}

	#incident {
		padding-right: 0px;
	}

	.DateInput_input__focused {
		outline: 0;
		background: transparent;
		border: 0;
		border-top: 0;
		border-right: 0;
		border-bottom: 2px solid #008489;
		border-left: 0;
		border-radius: 3px!important;
	}

	.DateInput_input {
		font-weight: 200;
		font-size: 19px;
		line-height: 24px;
		color: #484848;
		background-color: transparent!important;
		width: 100%;
		padding: 11px 11px 9px;
		border: 0;
		border-top: 0;
		border-right: 0;
		border-bottom: 2px solid transparent;
		border-left: 0;
		border-radius: 4px;
	}

	.DateInput {
		margin: 0px;
		padding: 0px;
		background: transparent!important;
		position: relative;
		display: inline-block;
		vertical-align: middle;
	}

	.SingleDatePickerInput__withBorder {
		border-radius: 4px!important;
		border: 2px solid #dbdbdb!important;
	}

}

@media (min-width: 768px) and (max-width: 991px) {
	.record {
		padding: 10px!important;
	}

	div.form-group.row {
		margin: 0px;
		width: 100%!important;
	}

	.CaseRecordChrome {
		background-color: #F9F9F9;
		margin: 1em 2em 2em 2em;
		padding: 5px;
		box-shadow: 1px 1px 8px #bbb;
	}

	.new-case-title {
		padding: 10px 0px 20px 8px;
		height: 5px!important;
	}

	.case-record-number-sm {
		display: none;
	}

	.case-record-number-md {
		display: none;
	}

	.case-record-number-lg {
		font-size: 16px;
		padding-left: 0px;
		padding-right: 0px;
		width: 12%;
	}

	.case-record-number-xl {
		display: none;
	}

	.number-field-sm {
		display: none;
	}

	.number-field-md {
		display: none;
	}

	.number-field-lg1 {
		width: 25%!important;
		margin-right: 0px!important;
	}

	.number-field-lg2 {
		/* This is for the FormControl field itself */
	}

	.number-field-xl {
		display: none;
	}

	.date-label-sm {
		display: none;
	}

	.date-label-md {
		display: none;
	}

	.date-label-lg {
		font-size: 16px;
		padding-left: 0px;
		padding-right: 0px;
		width: 12%;
	}

	.date-label-xl {
		display: none;
	}

	.date-field-sm {
		display: none;
	}

	.date-field-md {
		display: none;
	}

	.date-field-lg1 {
		padding-right: 0px;
		margin-right: 0px;
		width: 30%;
	}

	.date-field-xl {
		display: none;
	}

	.case-fields {
		width: 100%;
		font-size: 18px;
	}

	#corpName.case-fields {
		width: 100%;
		font-size: 18px;
		margin-right: 0px!important;
	}
	.urgent-sm {
		display: none;
	}

	.incident-sm {
		display: none;
	}

	.urgent-md {
		display: none;
	}

	.incident-md {
		display: none;
	}

	.urgent-lg {
		padding-left: 0px;
	}

	.incident-lg {
		padding: 0px;
	}
	
	.incident-date {
		padding-left: 10px;
	}

	.container-row {
		padding: 0px;
	}

	.col-vin {
		width: 50%;
	}

	.vehicle-vin-plate {
		width: 50%;
	}

	.col-plate {
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%;
		padding-right: 0px;
		padding-left: 10px;
	}

	.vehicle-year {
		padding-left: 0px;
		width: 20%!important;
	}

	.vehicle-make {
		padding-left: 10px;
		padding-right: 0px;
		width: 40%!important;
	}

	.vehicle-model {
		padding-left: 10px;
		padding-right: 0px;
		width: 40%!important;
	}

	#date-field1 {
	}

	#date-field2 {
	}

	#urgent-row-md {
		display: none;
	}

	#urgent-row-lg {
		padding-left: 0px;
	}

	.date-label {
		margin-right: 10px;
	}

	.DateInput_input__focused {
		outline: 0;
		background: transparent;
		border: 0;
		border-top: 0;
		border-right: 0;
		border-bottom: 2px solid #008489;
		border-left: 0;
		border-radius: 3px!important;
	}

	.DateInput_input {
		font-weight: 200;
		font-size: 19px;
		line-height: 24px;
		color: #484848;
		background-color: transparent!important;
		width: 100%;
		padding: 11px 11px 9px;
		border: 0;
		border-top: 0;
		border-right: 0;
		border-bottom: 2px solid transparent;
		border-left: 0;
		border-radius: 4px;
	}

	.DateInput {
		margin: 0px;
		padding: 0px;
		background: transparent!important;
		position: relative;
		display: inline-block;
		vertical-align: middle;
	}

	.SingleDatePickerInput__withBorder {
		border-radius: 4px!important;
		border: 2px solid #dbdbdb!important;
	}

}

@media (min-width: 992px) {
	.record {
		padding: 10px!important;
	}

	div.form-group.row {
		margin: 0px;
		width: 100%!important;
	}

	.CaseRecordChrome {
		background-color: #F9F9F9;
		margin: 1em 2em 2em 2em;
		padding: 5px;
		box-shadow: 1px 1px 8px #bbb;
	}

	.new-case-title {
		padding: 10px 0px 20px 8px;
		height: 5px!important;
	}

	.case-record-number-sm {
		display: none;
	}

	.case-record-number-md {
		display: none;
	}

	.case-record-number-lg {
		display: none;
	}

	.case-record-number-xl {
		font-size: 16px;
		padding-left: 0px;
		padding-right: 0px;
		width: 12%;
		max-width: 12%;
	}

	.number-field-sm {
		display: none;
	}

	.number-field-md {
		display: none;
	}

	.number-field-lg1 {
		display: none;
	}

	.number-field-lg2 {
		/* This is for the FormControl field itself */
		display: none;
	}

	.number-field-xl {
		width: 25%!important;
		margin-right: 0px!important;
		padding-left: 0px;
	}

	.date-label-sm {
		display: none;
	}

	.date-label-md {
		display: none;
	}

	.date-label-lg {
		display: none;
	}

	.date-label-xl {
		font-size: 16px;
		padding-top: 7px;
		padding-left: 0px;
		padding-right: 0px;
		width: 12%;
	}

	.date-field-sm {
		display: none;
	}

	.date-field-md {
		display: none;
	}

	.date-field-lg1 {
		display: none;
	}

	.date-field-xl {
		padding-right: 0px;
		margin-right: 0px;
		width: 30%;
	}

	.case-fields {
		width: 100%;
		font-size: 18px;
	}

	#corpName.case-fields {
		width: 100%;
		font-size: 18px;
	}

	.urgent-sm {
		display: none;
	}

	.incident-sm {
		display: none;
	}

	.urgent-md {
		display: none;
	}

	.incident-md {
		display: none;
	}

	.urgent-lg {
		display: none;
	}

	.incident-lg {
		display: none;
	}

	.urgent-xl {
		padding-left: 0px;
	}

	.incident-xl {
		padding: 0px;
	}
	
	.incident-date {
		padding-left: 10px;
	}

	.container-row {
		padding: 0px;
	}

	.col-vin {
		width: 50%;
	}

	.vehicle-vin-plate {
		width: 50%;
	}

	.col-plate {
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%;
		padding-right: 0px;
		padding-left: 10px;
	}

	.vehicle-year {
		padding-left: 0px;
		width: 20%!important;
	}

	.vehicle-make {
		padding-left: 10px;
		padding-right: 0px;
		width: 40%!important;
	}

	.vehicle-model {
		padding-left: 10px;
		padding-right: 0px;
		width: 40%!important;
	}

	#date-field1 {
	}

	#date-field2 {
	}

	#urgent-row-md {
		display: none;
	}

	#urgent-row-lg {
		padding-left: 0px;
	}

	.date-label {
		margin-right: 10px;
	}
}









/*

@media all and (max-width: 567px) {
	.CaseRecordChrome {
		padding: 10px;
	}
}

@media all and (min-width:568px) and (max-width: 1007px) {
	.CaseRecordChrome {
		background-color: #F9F9F9;
		margin: 2em 3em 3em 3em;
		padding: 5px;
		box-shadow: 1px 1px 8px #bbb;
	}
}

@media all and (min-width:1008px) {
	.CaseRecordChrome {
		background-color: #F9F9F9;
		margin: 2em 3em 3em 3em;
		padding: 5px;
		box-shadow: 1px 1px 8px #bbb;
	}
}
*/











/*

DEFAULT MEDIA QUERY RESPONSIVE TEMPLATING!!!!!!!



This is for global styles
@media all {
	
}

This is for device changing styles
@media (min-width: 319px) {

}

@media (min-width: 576px) {

}

@media (min-width: 768px) {

}

@media (min-width: 992px) {

}

@media (min-width: 1200px) {

}

@media (min-width: 1558px) {

}


*/
