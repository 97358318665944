.features-title-1 {
	margin-top: 20px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	line-height: 1.5;
	font-weight: 600;
	font-family: "Open Sans", "Segoe UI", "DejaVu Sans", sans-serif;
}

/*
.features-title-1a {
	margin-top: 700px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	line-height: 1.5;
	font-weight: 600;
	font-family: "Open Sans", "Segoe UI", "DejaVu Sans", sans-serif;
}

.features-title-2 {
	margin-top: 800px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	line-height: 1.5;
	font-weight: 600;
	font-family: "Open Sans", "Segoe UI", "DejaVu Sans", sans-serif;
}

.features-title-3 {
	margin-top: 400px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	line-height: 1.5;
	font-weight: 600;
	font-family: "Open Sans", "Segoe UI", "DejaVu Sans", sans-serif;
}

.features-title-4 {
	margin-top: 400px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	line-height: 1.5;
	font-weight: 600;
	font-family: "Open Sans", "Segoe UI", "DejaVu Sans", sans-serif;
}

.features-title-5 {
	margin-top: 500px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	line-height: 1.5;
	font-weight: 600;
	font-family: "Open Sans", "Segoe UI", "DejaVu Sans", sans-serif;
}

.features-title-6 {
	margin-top: 1050px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	line-height: 1.5;
	font-weight: 600;
	font-family: "Open Sans", "Segoe UI", "DejaVu Sans", sans-serif;
}

.features-title-7 {
	margin-top: 800px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	line-height: 1.5;
	font-weight: 600;
	font-family: "Open Sans", "Segoe UI", "DejaVu Sans", sans-serif;
}

.features-title-8 {
	margin-top: 850px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	line-height: 1.5;
	font-weight: 600;
	font-family: "Open Sans", "Segoe UI", "DejaVu Sans", sans-serif;
}

.features-title-9 {
	margin-top: 1200px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	line-height: 1.5;
	font-weight: 600;
	font-family: "Open Sans", "Segoe UI", "DejaVu Sans", sans-serif;
}

.features-title-10 {
	margin-top: 950px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	line-height: 1.5;
	font-weight: 600;
	font-family: "Open Sans", "Segoe UI", "DejaVu Sans", sans-serif;
}

.features-title-11 {
	margin-top: 1450px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	line-height: 1.5;
	font-weight: 600;
	font-family: "Open Sans", "Segoe UI", "DejaVu Sans", sans-serif;
}

.features-title-12 {
	margin-top: 1100px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	line-height: 1.5;
	font-weight: 600;
	font-family: "Open Sans", "Segoe UI", "DejaVu Sans", sans-serif;
}

.features-title-13 {
	margin-top: 700px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	line-height: 1.5;
	font-weight: 600;
	font-family: "Open Sans", "Segoe UI", "DejaVu Sans", sans-serif;
}

.features-title-14 {
	margin-top: 850px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	line-height: 1.5;
	font-weight: 600;
	font-family: "Open Sans", "Segoe UI", "DejaVu Sans", sans-serif;
}

.features-title-15 {
	margin-top: 1500px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	line-height: 1.5;
	font-weight: 600;
	font-family: "Open Sans", "Segoe UI", "DejaVu Sans", sans-serif;
}

.features-title-16 {
	margin-top: 1200px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	line-height: 1.5;
	font-weight: 600;
	font-family: "Open Sans", "Segoe UI", "DejaVu Sans", sans-serif;
}

.features-title-17 {
	margin-top: 2050px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	line-height: 1.5;
	font-weight: 600;
	font-family: "Open Sans", "Segoe UI", "DejaVu Sans", sans-serif;
}

.features-title-18 {
	margin-top: 800px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	line-height: 1.5;
	font-weight: 600;
	font-family: "Open Sans", "Segoe UI", "DejaVu Sans", sans-serif;
}

.features-title-11sP {
	margin-top: 700px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	line-height: 1.5;
	font-weight: 600;
	font-family: "Open Sans", "Segoe UI", "DejaVu Sans", sans-serif;
}

.features-title-11sL {
	margin-top: 450px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	line-height: 1.5;
	font-weight: 600;
	font-family: "Open Sans", "Segoe UI", "DejaVu Sans", sans-serif;
}

*/

.post {
    padding: 12px;
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
}

.horizon-flexbox {
	display: flex;
	flex-wrap: nowrap;
	overflow-x: auto;
	-webkit-overflow-scrolling: touch;
	-ms-overflow-style: -ms-autohiding-scrollbar;
	scrollbar-width: none;
}

.horizon-flexbox::-webkit-scrollbar {
	display: none;
}

.horizon-service-block {
	text-decoration: none!important;
}

.horizon-card {
	flex: 0 0 auto;
	box-sizing: border-box;
	width: 19em;
	height: 15em;
	border-style: solid;
	border-width: 0.0125em;
	border-radius: 1em;
	border-color: #bbb;
	margin: 30px;
	margin-bottom: 50px;
	box-shadow: 1px 1px 4px 1px #bbb;
	transition: .2s;
	-webkit-transition: .2s;
	cursor: pointer;
}

.horizon-card:hover, .horizon-card:focus, .horizon-card:active {
	background-color: #f4f8ff;
	box-shadow: 0px 0px 0px 0px #bbb;
	transition: .2s;
	-webkit-transition: .2s;
	cursor: pointer;
}

.horizon-icon {

}

.horizon-card-title {
	text-decoration: none!important;
	font-family: "Open Sans", "Segoe UI", "DejaVu Sans", sans-serif;
	font-weight: 550;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	padding: 5px;
	color: black;
}

.horizon-card-tagline {
	font-family: "Open Sans", "Segoe UI", "DejaVu Sans", sans-serif;
	font-size: 12px;
	padding-left: 14px;
	padding-right: 14px;
	text-align: justify;
}

#p {
	color: #273e70;
}



















