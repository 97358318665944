.tc-container {
	display: flex!important;
}

.tc-p {
	margin: 20px;
}

.tc-heading {
	padding-top: 20px;
}

section {
	color: #999!important;
	text-align: center;
}